<template>
  <div>
    <v-app>
      <s-navigation-app-bar :menu="false" @toggle="drawer.toggle()">
        <template v-slot:aftermenu>
          <s-translation-switcher />
          <v-btn id="login" :to="localePath('/login')" icon style="color: white"
            ><v-icon>mdi-login</v-icon></v-btn
          >
        </template>
      </s-navigation-app-bar>

      <s-navigation-drawer v-if="menu.length > 0" ref="drawer" :items="menu" />
      <v-main>
        <v-container class="pa-0" fluid>
          <slot />
        </v-container>
      </v-main>
      <s-navigation-footer />
    </v-app>
  </div>
</template>

<script setup>
const drawer = ref(null);
const menu = [];

defineExpose({
  drawer,
});
</script>